import { createMachine } from "xstate";
import { TextToSpeech } from "@capacitor-community/text-to-speech";

export const ttsMachine = createMachine({
  predictableActionArguments: true,
  initial: "stopped",
  states: {
    stopped: {
      invoke: { src: TextToSpeech.stop },
      on: {
        TOGGLE: "playing",
        PLAY: "playing",
      },
    },
    playing: {
      invoke: {
        src: (context, event) => {
          if (typeof event.text === "string") {
            return TextToSpeech.speak({ text: event.text });
          }
          throw new Error("type of text should be string!");
        },
        onDone: "stopped",
      },
      on: {
        STOP: "stopped",
        TOGGLE: "stopped",
        NEXT: "stopped",
        PREV: "stopped",
      },
    },
  },
});
