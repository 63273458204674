import {
  homeOutline,
  homeSharp,
  imagesOutline,
  imagesSharp,
  listOutline,
  listSharp,
  helpOutline,
  helpSharp,
  shieldHalfOutline,
  shieldHalfSharp,
} from "ionicons/icons";
import { initialData2008, initialData2020 } from "../helpers/variables";

type Route = {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  showInMenu: boolean;
  description?: string;
  menuTitle?: string;
};

type AppPage = {
  category: string | undefined;
  isOpen?: boolean;
  routes: Route[];
};

export const appPages: AppPage[] = [
  {
    category: undefined,
    routes: [
      {
        title: "Home",
        url: "/",
        iosIcon: homeOutline,
        mdIcon: homeSharp,
        showInMenu: true,
        description:
          "USA Naturalization Test and Flash Cards with voice assistant. Civic questions for interview and change status of USA citizenship. Latest version 2020-Current and previous 2008-2019 version.",
      },
      {
        title: "Home",
        url: "/home",
        iosIcon: homeOutline,
        mdIcon: homeSharp,
        showInMenu: false,
        description:
          "USA Naturalization Test and Flash Cards.Civic test and questions for interview and change status. Updated latest version 2020-Current and 2008-2019 version.",
      },
    ],
  },
  {
    category: "Version 2020-2024",
    isOpen: true,
    routes: [
      {
        title: "Questions (2020 version)",
        url: "/2020/questions",
        iosIcon: listOutline,
        mdIcon: listSharp,
        showInMenu: true,
        menuTitle: "Questions",
        description: initialData2020.description,
      },
      {
        title: "Flash Cards (version)",
        url: "/2020/flashcards",
        iosIcon: imagesOutline,
        mdIcon: imagesSharp,
        showInMenu: true,
        menuTitle: "Flash Cards",
        description: `Flash Cards (2020 version) - ${initialData2020.collectionTitle}`,
      },
    ],
  },
  {
    category: "Version 2008-2019",
    routes: [
      {
        title: "Questions (2008 version)",
        url: "/2008/questions",
        iosIcon: helpOutline,
        mdIcon: helpSharp,
        showInMenu: true,
        menuTitle: "Questions",
        description: initialData2008.description,
      },
      {
        title: "Flash Cards (2008 version)",
        url: "/2008/flashcards",
        iosIcon: imagesOutline,
        mdIcon: imagesSharp,
        showInMenu: true,
        menuTitle: "Flash Cards",
        description: `Flash Cards (2008 version) - ${initialData2008.collectionTitle}`,
      },
    ],
  },
  {
    category: "Other",
    routes: [
      {
        title: "Privacy Policy",
        url: "/privacy",
        iosIcon: shieldHalfOutline,
        mdIcon: shieldHalfSharp,
        showInMenu: true,
        menuTitle: "Privacy Policy",
        description: `(“we” or “us” or “our”) respects the privacy of our users (“user” or
      “you”). This Privacy Policy explains how we collect, use, disclose, and
      safeguard your information when you visit our mobile application (the
      “Application”).<strong>&nbsp;</strong>Please read this Privacy Policy
      carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY,
      PLEASE DO NOT ACCESS THE APPLICATION.`,
      },
    ],
  },
];
