import React from "react";
import { useInterpret } from "@xstate/react";
import { InterpreterFrom } from "xstate/lib/types";
import { ttsMachine } from "./TextToSpeechMachine";

export const TextToSpeechContext = React.createContext({
  ttsService: {} as InterpreterFrom<typeof ttsMachine>,
});

type Props = {
  children?: React.ReactNode;
};

export const TextToSpeechContextProvider: React.FC<Props> = ({ children }) => {
  const ttsService = useInterpret(ttsMachine);

  return (
    <TextToSpeechContext.Provider value={{ ttsService }}>
      {children}
    </TextToSpeechContext.Provider>
  );
};
