import React, { useState } from "react";
import { PageTitle } from "../typings/common";

type ContextType = {
  pageTitle: PageTitle;
  pageTitleSet: React.Dispatch<React.SetStateAction<PageTitle>>;
};

const defaultData: PageTitle = {
  title: "",
  subTitle: "",
};

export const PageTitleContext = React.createContext<ContextType>({
  pageTitle: defaultData,
  pageTitleSet: ((value: PageTitle) => {}) as React.Dispatch<
    React.SetStateAction<PageTitle>
  >,
});

type Props = {
  children?: React.ReactNode;
};

export const PageTitleContextProvider: React.FC<Props> = ({ children }) => {
  const [pageTitle, pageTitleSet] = useState<PageTitle>(defaultData);

  const state = {
    pageTitle,
    pageTitleSet,
  };

  return (
    <PageTitleContext.Provider value={state}>
      {children}
    </PageTitleContext.Provider>
  );
};
