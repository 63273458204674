import { IonContent, IonPage, IonSplitPane } from "@ionic/react";
import React from "react";
import Menu from "../../menu/Menu";
import { LayoutFooter } from "./LayoutFooter";
import { LayoutHeader } from "./LayoutHeader";

type Props = {
  children: React.ReactNode;
};

export const LayoutStructure: React.FC<Props> = ({ children }) => {
  return (
    <IonSplitPane contentId="main">
      <Menu />
      <IonPage className="ion-page" id="main">
        <LayoutHeader />

        <IonContent className="ion-padding">{children}</IonContent>

        {/* <LayoutFooter /> */}
      </IonPage>
    </IonSplitPane>
  );
};
