import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
} from "@ionic/react";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { appPages } from "../../routes/Routes";
import { PageTitleContext } from "../../state/PageTitleContext";

export const LayoutHeader: React.FC = () => {
  const location = useRouter();

  const pageRoute = appPages
    .map((el) => el.routes)
    .flat()
    .filter(
      (el) => el.url.toLowerCase() === location.pathname.toLowerCase()
    )?.[0];

  let title, description;
  if (pageRoute) {
    title = pageRoute.title;
    description = pageRoute.description;
  }

  const { pageTitle } = useContext(PageTitleContext);

  return (
    <IonHeader>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <title>
          {title ?? pageTitle.title} | US Citizenship / Civic Interview
          Questions
        </title>
        {!(pageTitle.subTitle || description) ? null : (
          <meta
            name="description"
            content={description ?? pageTitle.subTitle}
            key="desc"
          />
        )}
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle className="ion-text-center">
          {title ?? pageTitle.title}
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};
