import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";

import { appPages } from "../routes/Routes";
import { useRouter } from "next/router";
import Link from "next/link";
import { useState } from "react";

const Menu: React.FC = () => {
  const router = useRouter();
  const pathname = router.pathname;

  const topLevelRoutes = appPages
    .filter((el) => !el.category)
    .map((item) => item.routes)
    .flat()
    .filter((el) => el.showInMenu);

  const secondLevelRoutes = appPages.filter((el) => el.category);
  const [expandedAccordions, expandedAccordionsSet] = useState<string>(
    secondLevelRoutes.filter((el) => el.isOpen).map((el) => el.category!)[0]
  );
  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList>
          {topLevelRoutes.map((item) => {
            return (
              <IonMenuToggle key={item.title} slot="content" autoHide={false}>
                <Link href={item.url}>
                  <IonItem
                    className={pathname === item.url ? "selected" : ""}
                    lines="none"
                    detail={false}
                    href={item.url}
                  >
                    <IonIcon slot="start" ios={item.iosIcon} md={item.mdIcon} />
                    <IonLabel>{item.menuTitle ?? item.title}</IonLabel>
                  </IonItem>
                </Link>
              </IonMenuToggle>
            );
          })}
          <IonAccordionGroup
            multiple={false}
            value={expandedAccordions}
            onIonChange={(e) => {
              if (e.detail.value !== expandedAccordions) {
                expandedAccordionsSet(e.detail.value);
              }
            }}
          >
            {secondLevelRoutes.map((el) => {
              return (
                <IonAccordion key={el.category} value={el.category}>
                  <IonItem slot="header">{el.category}</IonItem>
                  {el.routes
                    .filter((el) => el.showInMenu)
                    .map((item) => {
                      return (
                        <IonMenuToggle
                          key={item.title}
                          slot="content"
                          autoHide={false}
                        >
                          <Link href={item.url}>
                            <IonItem
                              className={
                                pathname === item.url ? "selected" : ""
                              }
                              lines="none"
                              detail={false}
                              href={item.url}
                            >
                              <IonIcon
                                slot="start"
                                ios={item.iosIcon}
                                md={item.mdIcon}
                              />
                              <IonLabel>
                                {item.menuTitle ?? item.title}
                              </IonLabel>
                            </IonItem>
                          </Link>
                        </IonMenuToggle>
                      );
                    })}
                </IonAccordion>
              );
            })}
          </IonAccordionGroup>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
