import React, { useState } from "react";

type VersionYears = "2008" | "2020";
type ContextType = {
  versionYear: VersionYears;
  versionYearSet: React.Dispatch<React.SetStateAction<VersionYears>>;
};

export const VersionYearContext = React.createContext<ContextType>({
  versionYear: "2020",
  versionYearSet: ((value: VersionYears) => {}) as React.Dispatch<
    React.SetStateAction<VersionYears>
  >,
});

type Props = {
  children?: React.ReactNode;
};

export const VersionYearContextProvider: React.FC<Props> = ({ children }) => {
  const [versionYear, versionYearSet] = useState<VersionYears>("2020");

  const state = {
    versionYear,
    versionYearSet,
  };

  return (
    <VersionYearContext.Provider value={state}>
      {children}
    </VersionYearContext.Provider>
  );
};
