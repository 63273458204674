import type { AppProps } from "next/app";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "../styles/globals.css";

/* Theme variables */
import "../theme/variables.css";

// Menu styling
import "../menu/Menu.css";

// google analytics
import * as gtag from "../helpers/gtag";
import Script from "next/script";

import { IonApp, setupIonicReact } from "@ionic/react";
import NoSSRWrapper from "../components/NoSSRWrapper";
import { LayoutStructure } from "../components/layout/LayoutStructure";
import { ContextWrapper } from "../state/ContextWrapper";
import { useRouter } from "next/router";
import { useEffect } from "react";

setupIonicReact();
function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("hashChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <NoSSRWrapper>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <IonApp style={{ maxWidth: 750, margin: "0 auto" }}>
        <ContextWrapper>
          <LayoutStructure>
            <Component {...pageProps} />
          </LayoutStructure>
        </ContextWrapper>
      </IonApp>
    </NoSSRWrapper>
  );
}

export default MyApp;
