import React from "react";
import { PageTitleContextProvider } from "./PageTitleContext";
import { TextToSpeechContextProvider } from "./TextToSpeechContext";
import { VersionYearContextProvider } from "./VersionYearContext";

type Props = {
  children?: React.ReactNode;
};

/** wraps all context provers to have cleaner code */
export const ContextWrapper: React.FC<Props> = ({ children }) => {
  return (
    <PageTitleContextProvider>
      <TextToSpeechContextProvider>
        <VersionYearContextProvider>{children}</VersionYearContextProvider>
      </TextToSpeechContextProvider>
    </PageTitleContextProvider>
  );
};
