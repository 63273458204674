export type Question = {
  seq: string;
  question: string;
  answers: string[];
  older65?: boolean;
  category?: string;
  subCategory?: string;
};

type InitialData = {
  collectionTitle: string;
  description: string;
  questions: Question[];
};

export type VersionYear = "2008" | "2020";

export const initialData2008: InitialData = {
  collectionTitle:
    "Civics (History and Government) Questions for the Naturalization Test (2008 version)",
  description: `The 100 civics (history and government) questions and answers for the naturalization test are listed below. The civics test is an oral test and the USCIS Officer will ask the applicant up to 10 of the 100 civics questions. An applicant must answer 6 out of 10 questions correctly to pass the civics portion of the naturalization test. 

  On the naturalization test, some answers may change because of elections or appointments. As you study for the test, make sure that you know the most current answers to these questions. Answer these questions with the name of the official who is serving at the time of your eligibility interview with USCIS. The USCIS Officer will not accept an incorrect answer. 
  
  Although USCIS is aware that there may be additional correct answers to the 100 civics questions, applicants are encouraged to respond to the civics questions using the answers provided below. 
  
  * If you are 65 years old or older and have been a legal permanent resident of the United States for 20 or more years, you may study just the questions that have been marked with an asterisk. 
  `,
  questions: [
    {
      seq: "1",
      question: "What is the supreme law of the land?",
      answers: ["the Constitution"],
    },
    {
      seq: "2",
      question: "What does the Constitution do?",
      answers: [
        "sets up the government",
        "defines the government",
        "protects basic rights of Americans",
      ],
    },
    {
      seq: "3",
      question:
        "The idea of self-government is in the first three words of the Constitution. What are these words?",
      answers: ["We the People"],
    },
    {
      seq: "4",
      question: "What is an amendment?",
      answers: [
        "a change (to the Constitution)",
        "an addition (to the Constitution)",
      ],
    },
    {
      seq: "5",
      question: "What do we call the first ten amendments to the Constitution?",
      answers: ["the Bill of Rights"],
    },
    {
      older65: true,
      seq: "6",
      question: "What is one right or freedom from the First Amendment?",
      answers: [
        "speech",
        "religion",
        "assembly",
        "press",
        "petition the government",
      ],
    },
    {
      seq: "7",
      question: "How many amendments does the Constitution have?",
      answers: ["twenty-seven (27)"],
    },
    {
      seq: "8",
      question: "What did the Declaration of Independence do?",
      answers: [
        "announced our independence (from Great Britain)",
        "declared our independence (from Great Britain)",
        "said that the United States is free (from Great Britain)",
      ],
    },
    {
      seq: "9",
      question: "What are two rights in the Declaration of Independence?",
      answers: ["life", "liberty", "pursuit of happiness"],
    },
    {
      seq: "10",
      question: "What is freedom of religion?",
      answers: ["You can practice any religion, or not practice a religion."],
    },
    {
      older65: true,
      seq: "11",
      question: "What is the economic system in the United States?",
      answers: ["capitalist economy", "market economy"],
    },
    {
      seq: "12",
      question: "What is the “rule of law”?",
      answers: [
        "Everyone must follow the law.",
        "Leaders must obey the law.",
        "Government must obey the law.",
        "No one is above the law. ",
      ],
    },
    {
      older65: true,
      seq: "13",
      question: "Name one branch or part of the government.",
      answers: [
        "Congress",
        "legislative",
        "President",
        "executive",
        "the courts",
        "judicial",
      ],
    },
    {
      seq: "14",
      question:
        "What stops one branch of government from becoming too powerful?",
      answers: ["checks and balances", "separation of powers"],
    },
    {
      seq: "15",
      question: "Who is in charge of the executive branch?",
      answers: ["the President"],
    },
    {
      seq: "16",
      question: "Who makes federal laws?",
      answers: [
        "Congress",
        "Senate and House (of Representatives)",
        "(U.S. or national) legislature",
      ],
    },
    {
      older65: true,
      seq: "17",
      question: "What are the two parts of the U.S. Congress?",
      answers: ["the Senate and House (of Representatives)"],
    },
    {
      seq: "18",
      question: "How many U.S. Senators are there?",
      answers: ["one hundred (100)"],
    },
    {
      seq: "19",
      question: "We elect a U.S. Senator for how many years?",
      answers: ["six (6)"],
    },
    {
      older65: true,
      seq: "20",
      question: "Who is one of your state’s U.S. Senators now?",
      answers: [
        "Answers will vary. [District of Columbia residents and residents of U.S. territories should answer that D.C.(or the territory where the applicant lives) has no U.S. Senators.",
      ],
    },

    {
      seq: "21",
      question: "The House of Representatives has how many voting members?",
      answers: ["four hundred thirty-five (435)"],
    },
    {
      seq: "22",
      question: "We elect a U.S. Representative for how many years?",
      answers: ["two (2)"],
    },
    {
      seq: "23",
      question: "Name your U.S. Representative.",
      answers: [
        "Answers will vary. [Residents of territories with nonvoting Delegates or Resident Commissioners may provide the name of that Delegate or Commissioner. Also acceptable is any statement that the territory has no (voting) Representatives in Congress.",
      ],
    },
    {
      seq: "24",
      question: "Who does a U.S. Senator represent?",
      answers: ["all people of the state"],
    },
    {
      seq: "25",
      question:
        "Why do some states have more Representatives than other states?",
      answers: [
        "(because of) the state’s population",
        "(because) they have more people",
        "(because) some states have more people",
      ],
    },
    {
      seq: "26",
      question: "We elect a President for how many years?",
      answers: ["four (4)"],
    },
    {
      older65: true,
      seq: "27",
      question: "In what month do we vote for President?",
      answers: ["November"],
    },
    {
      older65: true,
      seq: "28",
      question: "What is the name of the President of the United States now?",
      answers: [
        "Visit uscis.gov/citizenship/testupdates for the name of the President of the United States.",
      ],
    },
    {
      seq: "29",
      question:
        "What is the name of the Vice President of the United States now?",
      answers: [
        "Visit uscis.gov/citizenship/testupdates for the name of the Vice President of the United States.",
      ],
    },
    {
      seq: "30",
      question: "If the President can no longer serve, who becomes President?",
      answers: ["the Vice President"],
    },
    {
      seq: "31",
      question:
        "If both the President and the Vice President can no longer serve, who becomes President?",
      answers: ["the Speaker of the House"],
    },
    {
      seq: "32",
      question: "Who is the Commander in Chief of the military?",
      answers: ["the President"],
    },
    {
      seq: "33",
      question: "Who signs bills to become laws?",
      answers: ["the President"],
    },
    { seq: "34", question: "Who vetoes bills?", answers: ["the President"] },
    {
      seq: "35",
      question: "What does the President’s Cabinet do?",
      answers: ["advises the President"],
    },
    {
      seq: "36",
      question: "What are two Cabinet-level positions?",
      answers: [
        "Secretary of Agriculture",
        "Secretary of Commerce",
        "Secretary of Defense",
        "Secretary of Education",
        "Secretary of Energy",
        "Secretary of Health and Human Services",
        "Secretary of Homeland Security",
        "Secretary of Housing and Urban Development",
        "Secretary of the Interior",
        "Secretary of Labor",
        "Secretary of State",
        "Secretary of Transportation",
        "Secretary of the Treasury",
        "Secretary of Veterans Affairs",
        "Attorney General",
        "Vice President",
      ],
    },
    {
      seq: "37",
      question: "What does the judicial branch do?",
      answers: [
        "reviews laws",
        "explains laws",
        "resolves disputes (disagreements)",
        "decides if a law goes against the Constitution",
      ],
    },
    {
      seq: "38",
      question: "What is the highest court in the United States?",
      answers: ["the Supreme Court"],
    },
    {
      seq: "39",
      question: "How many justices are on the Supreme Court?",
      answers: [
        "Visit uscis.gov/citizenship/testupdates for the number of justices on the Supreme Court.",
      ],
    },
    {
      seq: "40",
      question: "Who is the Chief Justice of the United States now?",
      answers: [
        "Visit uscis.gov/citizenship/testupdates for the name of the Chief Justice of the United States.",
      ],
    },
    {
      seq: "41",
      question:
        "Under our Constitution, some powers belong to the federal government. What is one power of the federal government?",

      answers: [
        "to print money",
        "to declare war",
        "to create an army",
        "to make treaties",
      ],
    },
    {
      seq: "42",
      question:
        "Under our Constitution, some powers belong to the states. What is one power of the states?",
      answers: [
        "provide schooling and education",
        "provide protection (police)",
        "provide safety (fire departments)",
        "give a driver’s license",
        "approve zoning and land use",
      ],
    },
    {
      seq: "43",
      question: "Who is the Governor of your state now?",
      answers: [
        "Answers will vary. [District of Columbia residents should answer that D.C. does not have a Governor.]},",
      ],
    },
    {
      older65: true,
      seq: "44",
      question: "What is the capital of your state?",
      answers: [
        "Answers will vary. [District of Columbia residents should answer that D.C. is not a state and does not have a capital. Residents of U.S. territories should name the capital of the territory.",
      ],
    },

    {
      older65: true,
      seq: "45",
      question:
        "What are the two major political parties in the United States?",
      answers: ["Democratic and Republican"],
    },
    {
      seq: "46",
      question: "What is the political party of the President now?",
      answers: [
        "Visit uscis.gov/citizenship/testupdates for the political party of the President.",
      ],
    },
    {
      seq: "47",
      question:
        "What is the name of the Speaker of the House of Representatives now?",
      answers: [
        "Visit uscis.gov/citizenship/testupdates for the name of the Speaker of the House of Representatives.",
      ],
    },
    {
      seq: "48",
      question:
        "There are four amendments to the Constitution about who can vote. Describe one of them.",
      answers: [
        "Citizens eighteen (18) and older (can vote).",
        "You don’t have to pay (a poll tax) to vote.",
        "Any citizen can vote. (Women and men can vote.)",
        "A male citizen of any race (can vote).",
      ],
    },
    {
      seq: "49",
      older65: true,
      question:
        "What is one responsibility that is only for United States citizens?",
      answers: ["serve on a jury", "vote in a federal election"],
    },
    {
      seq: "50",
      question: "Name one right only for United States citizens.",
      answers: ["vote in a federal election", "run for federal office"],
    },
    {
      seq: "51",
      question: "What are two rights of everyone living in the United States?",
      answers: [
        "freedom of expression",
        "freedom of speech",
        "freedom of assembly",
        "freedom to petition the government",
        "freedom of religion",
        "the right to bear arms",
      ],
    },
    {
      seq: "52",
      question:
        "What do we show loyalty to when we say the Pledge of Allegiance?",
      answers: ["the United States", "the flag"],
    },
    {
      seq: "53",
      question:
        "What is one promise you make when you become a United States citizen?",
      answers: [
        "give up loyalty to other countries",
        "defend the Constitution and laws of the United States",
        "obey the laws of the United States",
        "serve in the U.S. military (if needed)",
        "serve (do important work for) the nation (if needed)",
        "be loyal to the United States",
      ],
    },
    {
      older65: true,
      seq: "54",
      question: "How old do citizens have to be to vote for President?",
      answers: ["eighteen (18) and older"],
    },
    {
      seq: "55",
      question:
        "What are two ways that Americans can participate in their democracy?",
      answers: [
        "vote",
        "join a political party",
        "help with a campaign",
        "join a civic group",
        "join a community group",
        "give an elected official your opinion on an issue",
        "call Senators and Representatives",
        "publicly support or oppose an issue or policy",
        "run for office",
        "write to a newspaper",
      ],
    },
    {
      older65: true,
      seq: "56",
      question:
        "When is the last day you can send in federal income tax forms?",
      answers: ["April 15"],
    },
    {
      seq: "57",
      question: "When must all men register for the Selective Service?",
      answers: [
        "at age eighteen (18)",
        "between eighteen (18) and twenty-six (26)",
      ],
    },
    {
      seq: "58",
      question: "What is one reason colonists came to America?",
      answers: [
        "freedom",
        "political liberty",
        "religious freedom",
        "economic opportunity",
        "practice their religion",
        "escape persecution",
      ],
    },
    {
      seq: "59",
      question: "Who lived in America before the Europeans arrived?",
      answers: ["American Indians", "Native Americans"],
    },
    {
      seq: "60",
      question: "What group of people was taken to America and sold as slaves?",
      answers: ["Africans", "people from Africa"],
    },
    {
      seq: "61",
      question: "Why did the colonists fight the British?",
      answers: [
        "because of high taxes (taxation without representation)",
        "because the British army stayed in their houses (boarding, quartering)",
        "because they didn’t have self-government",
      ],
    },
    {
      seq: "62",
      question: "Who wrote the Declaration of Independence?",
      answers: ["(Thomas) Jefferson"],
    },
    {
      seq: "63",
      question: "When was the Declaration of Independence adopted?",
      answers: ["July 4, 1776"],
    },
    {
      seq: "64",
      question: "There were 13 original states. Name three.",
      answers: [
        "New Hampshire",
        "Massachusetts",
        "Rhode Island",
        "Connecticut",
        "New York",
        "New Jersey",
        "Pennsylvania",
        "Delaware",
        "Maryland",
        "Virginia",
        "North Carolina",
        "South Carolina",
        "Georgia",
      ],
    },
    {
      seq: "65",
      question: "What happened at the Constitutional Convention?",
      answers: [
        "The Constitution was written.",
        "The Founding Fathers wrote the Constitution.",
      ],
    },
    {
      seq: "66",
      question: "When was the Constitution written?",
      answers: ["1787"],
    },
    {
      seq: "67",
      question:
        "The Federalist Papers supported the passage of the U.S. Constitution. Name one of the writers.",
      answers: [
        "(James) Madison",
        "(Alexander) Hamilton",
        "(John) Jay",
        "Publius",
      ],
    },
    {
      seq: "68",
      question: "What is one thing Benjamin Franklin is famous for?",
      answers: [
        "U.S. diplomat",
        "oldest member of the Constitutional Convention",
        "first Postmaster General of the United States",
        "writer of “Poor Richard’s Almanac”",
        "started the first free libraries",
      ],
    },
    {
      seq: "69",
      question: "Who is the “Father of Our Country”?",
      answers: ["(George) Washington"],
    },
    {
      older65: true,
      seq: "70",
      question: "Who was the first President?",
      answers: ["(George) Washington"],
    },
    {
      seq: "71",
      question: "What territory did the United States buy from France in 1803?",
      answers: ["the Louisiana Territory", "Louisiana"],
    },
    {
      seq: "72",
      question: "Name one war fought by the United States in the 1800s.",
      answers: [
        "War of 1812",
        "Mexican-American War",
        "Civil War",
        "Spanish-American War",
      ],
    },
    {
      seq: "73",
      question: "Name the U.S. war between the North and the South.",
      answers: ["the Civil War", "the War between the States"],
    },
    {
      seq: "74",
      question: "Name one problem that led to the Civil War.",
      answers: ["slavery", "economic reasons", "states’ rights"],
    },
    {
      older65: true,
      seq: "75",
      question: "What was one important thing that Abraham Lincoln did?",
      answers: [
        "freed the slaves (Emancipation Proclamation)",
        "saved (or preserved) the Union",
        "led the United States during the Civil War",
      ],
    },
    {
      seq: "76",
      question: "What did the Emancipation Proclamation do?",
      answers: [
        "freed the slaves",
        "freed slaves in the Confederacy",
        "freed slaves in the Confederate states",
        "freed slaves in most Southern states",
      ],
    },
    {
      seq: "77",
      question: "What did Susan B. Anthony do?",
      answers: ["fought for women’s rights", "fought for civil rights"],
    },
    {
      older65: true,
      seq: "78",
      question: "Name one war fought by the United States in the 1900s.",
      answers: [
        "World War I",
        "World War II",
        "Korean War",
        "Vietnam War",
        "(Persian) Gulf War",
      ],
    },
    {
      seq: "79",
      question: "Who was President during World War I?",
      answers: ["(Woodrow) Wilson"],
    },
    {
      seq: "80",
      question:
        "Who was President during the Great Depression and World War II?",
      answers: ["(Franklin) Roosevelt"],
    },
    {
      seq: "81",
      question: "Who did the United States fight in World War II?",
      answers: ["Japan, Germany, and Italy"],
    },
    {
      seq: "82",
      question:
        "Before he was President, Eisenhower was a general. What war was he in?",
      answers: ["World War II"],
    },
    {
      seq: "83",
      question:
        "During the Cold War, what was the main concern of the United States?",
      answers: ["Communism"],
    },
    {
      seq: "84",
      question: "What movement tried to end racial discrimination?",
      answers: ["civil rights (movement)"],
    },
    {
      older65: true,
      seq: "85",
      question: "What did Martin Luther King, Jr. do?",
      answers: [
        "fought for civil rights",
        "worked for equality for all Americans",
      ],
    },
    {
      seq: "86",
      question:
        "What major event happened on September 11, 2001, in the United States?",
      answers: ["Terrorists attacked the United States."],
    },
    {
      seq: "87",
      question: "Name one American Indian tribe in the United States.",
      answers: [
        "Cherokee",

        "Navajo",
        "Sioux",
        "Chippewa",
        "Choctaw",
        "Pueblo",
        "Apache",
        "Iroquois",
        "Creek",
        "Blackfeet",
        "Seminole",
        "Cheyenne",
        "Arawak",
        "Shawnee",
        "Mohegan",
        "Huron",
        "Oneida",
        "Lakota",
        "Crow",
        "Teton",
        "Hopi",
        "Inuit",
      ],
    },
    {
      seq: "88",
      question: "Name one of the two longest rivers in the United States.",
      answers: ["Missouri (River)", "Mississippi (River)"],
    },
    {
      seq: "89",
      question: "What ocean is on the West Coast of the United States?",
      answers: ["Pacific (Ocean)"],
    },
    {
      seq: "90",
      question: "What ocean is on the East Coast of the United States?",
      answers: ["Atlantic (Ocean)"],
    },
    {
      seq: "91",
      question: "Name one U.S. territory.",
      answers: [
        "Puerto Rico",
        "U.S. Virgin Islands",
        "American Samoa",
        "Northern Mariana Islands",
        "Guam",
      ],
    },
    {
      seq: "92",
      question: "Name one state that borders Canada.",
      answers: [
        "Maine",
        "New Hampshire",
        "Vermont",
        "New York",
        "Pennsylvania",
        "Ohio",
        "Michigan",
        "Minnesota",
        "North Dakota",
        "Montana",
        "Idaho",
        "Washington",
        "Alaska",
      ],
    },
    {
      seq: "93",
      question: "Name one state that borders Mexico.",
      answers: ["California", "Arizona", "New Mexico", "Texas"],
    },
    {
      older65: true,
      seq: "94",
      question: "What is the capital of the United States?",
      answers: ["Washington, D.C."],
    },
    {
      older65: true,
      seq: "95",
      question: "Where is the Statue of Liberty?",
      answers: [
        "New York (Harbor)",
        "Liberty Island",
        "[Also acceptable are New Jersey, near New York City, and on the Hudson (River).",
      ],
    },

    {
      seq: "96",
      question: "Why does the flag have 13 stripes?",
      answers: [
        "because there were 13 original colonies",
        "because the stripes represent the original colonies",
      ],
    },
    {
      older65: true,
      seq: "97",
      question: "Why does the flag have 50 stars?",
      answers: [
        "because there is one star for each state",
        "because each star represents a state",
        "because there are 50 states",
      ],
    },
    {
      seq: "98",
      question: "What is the name of the national anthem?",
      answers: ["The Star-Spangled Banner"],
    },
    {
      older65: true,
      seq: "99",
      question: "When do we celebrate Independence Day?",
      answers: ["July 4"],
    },
    {
      seq: "100",
      question: "Name two national U.S. holidays.",
      answers: [
        "New Year’s Day",
        "Martin Luther King, Jr. Day",
        "Presidents’ Day",
        "Memorial Day",
        "Independence Day",
        "Labor Day",
        "Columbus Day",
        "Veterans Day",
        "Christmas",
        "Thanksgiving",
      ],
    },
  ],
};

export const initialData2020: InitialData = {
  collectionTitle: "128 Civics Quesitons and Answers (2020 version)",
  description: `Listed below are the 128 civics questions and answers for the 2020 version of the civics test. These questions cover important topics about American government and history. The civics test is an oral test and the USCIS officer will ask you to answer 20 out of the 128 civics test questions. You must answer at least 12 questions (or 60%) correctly to pass the 2020 version of the civics test. 

  On the civics test, some answers may change because of elections or appointments. Visit uscis.gov/citizenship/testupdates to find any answers that may have changed on the civics test. You must answer the question with the name of the official serving at the time of your naturalization interview. 
  
  Although USCIS is aware that there may be additional correct answers to the civics questions, applicants are encouraged to respond to the questions using the answers provided below.
  
  65/20 Special Consideration 
  If you are 65 years old or older and have been living in the United States as a lawful permanent resident of the United States for 20 or more years, you may study just the 20 questions that have been marked with an asterisk (*) found at the end of each question. You may also take the civics test in the language of your choice. The USCIS officer will ask you to answer 10 out of the 20 civics test questions with an asterisk. You must answer at least 6 out of 10 questions (or 60%) correctly to pass the 2020 version of the civics test.`,
  questions: [
    {
      category: "AMERICAN GOVERNMENT",
      subCategory: "A: Principles of American Government",
      seq: "1",
      question: "What is the form of government of the United States?",
      answers: [
        "Republic",
        "Constitution-based federal republic",
        "Representative democracy",
      ],
    },
    {
      seq: "2",
      older65: true,
      question: "What is the supreme law of the land?",
      answers: ["(U.S.) Constitution"],
    },
    {
      seq: "3",
      question: "Name one thing the U.S. Constitution does.",
      answers: [
        "Forms the government",
        "Defines powers of government",
        "Defines the parts of government",
        "Protects the rights of the people",
      ],
    },
    {
      seq: "4",
      question:
        'The U.S. Constitution starts with the words "We the People. What does "We the People" mean?',
      answers: [
        "Self-government",
        "Popular sovereignty",
        "Consent of the governed",
        "People should govern themselves",
        "(Example of) social contract",
      ],
    },
    {
      seq: "5",
      question: "How are changes made to the U.S. Constitution?",
      answers: ["Amendments", "The amendment process"],
    },
    {
      seq: "6",
      question: "What does the Bill of Rights protect?",
      answers: [
        "(The basic) rights of Americans",
        "(The basic) rights of people living in the United States",
      ],
    },
    {
      seq: "7",
      older65: true,
      question: "How many amendments does the U.S. Constitution have?",
      answers: ["Twenty-seven (27)"],
    },
    {
      seq: "8",
      question: "Why is the Declaration of Independence important?",
      answers: [
        "It says America is free from British control.",
        "It says all people are created equal.",
        "It identifies inherent rights.",
        "It identifies individual freedoms.",
      ],
    },
    {
      seq: "9",
      question:
        "What founding document said the American colonies were free from Britain?",
      answers: ["Declaration of Independence"],
    },
    {
      seq: "10",
      question:
        "Name two important ideas from the Declaration of Independence and the U.S. Constitution.",
      answers: [
        "Equality",
        "Liberty",
        "Social contract",
        "Natural rights",
        "Limited government",
        "Self-government",
      ],
    },
    {
      seq: "11",
      question:
        'The words "Life, Liberty, and the pursuit of Happiness" are in what founding document?',
      answers: ["Declaration of Independence"],
    },
    {
      seq: "12",
      older65: true,
      question: "What is the economic system of the United States?",
      answers: ["Capitalism", "Free market economy"],
    },
    {
      seq: "13",
      question: "What is the rule of law?",
      answers: [
        "Everyone must follow the law.",
        "Leaders must obey the law.",
        "Government must obey the law.",
        "No one is above the law.",
      ],
    },
    {
      seq: "14",
      question: "Many documents influenced the U.S. Constitution. Name one.",
      answers: [
        "Articles of Confederation",
        "Anti-Federalist Papers",
        "Fundamental Orders of Connecticut",
        "Declaration of Independence",
        "Iroquois Great Law of Peace",
        "Federalist Papers",
        "Virginia Declaration of Rights",
        "Mayflower Compact",
      ],
    },
    {
      seq: "15",
      question: "There are three branches of government. Why?",
      answers: [
        "So one part does not become too powerful",
        "Checks and balances",
        "Separation of powers",
      ],
    },
    {
      seq: "16",
      question: "Name the three branches of government.",
      answers: [
        "Legislative, executive, and judicial",
        "Congress, president, and the courts",
      ],
    },
    {
      seq: "17",
      question:
        "The President of the United States is in charge of which branch of government?",
      answers: ["Executive branch"],
    },
    {
      seq: "18",
      question: "What part of the federal government writes laws?",
      answers: [
        "(U.S.) Congress",
        "(U.S. or national) legislature",
        "Legislative branch",
      ],
    },
    {
      seq: "19",
      question: "What are the two parts of the U.S. Congress?",
      answers: ["Senate and House (of Representatives)"],
    },
    {
      seq: "20",
      older65: true,
      question: "Name one power of the U.S. Congress.",
      answers: ["Writes laws", "Declares war", "Makes the federal budget"],
    },
    {
      seq: "21",
      question: "How many U.S. senators are there?",
      answers: ["One hundred (100)"],
    },
    {
      seq: "22",
      question: "How long is a term for a U.S. senator?",
      answers: ["Six (6) years "],
    },
    {
      seq: "23",
      question: "Who is one of your state's U.S. senators now?",
      answers: [
        "Answers will vary. [District of Columbia residents and residents of U.S. territories should answer that D.C. (or the territory where the applicant lives) has no U.S. senators.]",
      ],
    },
    {
      seq: "24",
      question: "How many voting members are in the House of Representatives?",
      answers: ["Four hundred thirty-five (435)"],
    },
    {
      seq: "25",
      question:
        "How long is a term for a member of the House of Representatives?",
      answers: ["Two (2) years"],
    },
    {
      seq: "26",
      question:
        "Why do U.S. representatives serve shorter terms than U.S. senators?",
      answers: ["To more closely follow public opinion"],
    },
    {
      seq: "27",
      question: "How many senators does each state have?",
      answers: ["Two (2)"],
    },
    {
      seq: "28",
      question: "Why does each state have two senators?",
      answers: [
        "Equal representation (for small states)",
        "The Great Compromise (Connecticut Compromise)",
      ],
    },
    {
      seq: "29",
      question: "Name your U.S. representative. ",
      answers: [
        "Answers will vary. [Residents of territories with nonvoting Delegates or Resident Commissioners may provide the name of that Delegate or Commissioner. Also acceptable is any statement that the territory has no (voting) representatives in Congress.]",
      ],
    },
    {
      seq: "30",
      older65: true,
      question:
        "What is the name of the Speaker of the House of Representatives now? ",
      answers: [
        "Visit uscis.gov/citizenship/testupdates for the name of the Speaker of the House of Representatives.",
      ],
    },
    {
      seq: "31",
      question: "Who does a U.S. senator represent?",
      answers: ["Citizens of their state", "People of their state"],
    },
    {
      seq: "32",
      question: "Who elects U.S. senators?",
      answers: ["Citizens from their state"],
    },
    {
      seq: "33",
      question: "Who does a member of the House of Representatives represent?",
      answers: [
        "Citizens in their (congressional) district",
        "Citizens in their district",
        "People from their (congressional) district",
        "People in their district",
      ],
    },
    {
      seq: "34",
      question: "Who elects members of the House of Representatives? ",
      answers: ["Citizens from their (congressional) district"],
    },
    {
      seq: "35",
      question: "Some states have more representatives than other states. Why?",
      answers: [
        "(Because of) the state's population",
        "(Because) they have more people",
        "(Because) some states have more people",
      ],
    },
    {
      seq: "36",
      older65: true,
      question:
        "The President of the United States is elected for how many years? ",
      answers: ["Four (4) years"],
    },
    {
      seq: "37",
      question:
        "The President of the United States can serve only two terms. Why?",
      answers: [
        "(Because of) the 22nd Amendment",
        "To keep the president from becoming too powerful",
      ],
    },
    {
      seq: "38",
      older65: true,
      question: "What is the name of the President of the United States now?",
      answers: [
        "Visit uscis.gov/citizenship/testupdates for the name of the President of the United States.",
      ],
    },
    {
      seq: "39",
      older65: true,
      question:
        "What is the name of the Vice President of the United States now? ",
      answers: [
        "Visit uscis.gov/citizenship/testupdates for the name of the Vice President of the United States.",
      ],
    },
    {
      seq: "40",
      question: "If the president can no longer serve, who becomes president? ",
      answers: ["The Vice President (of the United States)"],
    },

    {
      seq: "41",
      question: "Name one power of the president.  ",
      answers: [
        "Signs bills into law",
        "Vetoes bills",
        "Enforces laws",
        "Commander in Chief (of the military)",
        "Chief diplomat",
      ],
    },

    {
      seq: "42",
      question: "Who is Commander in Chief of the U.S. military?",
      answers: ["The President (of the United States)"],
    },

    {
      seq: "43",
      question: "Who signs bills to become laws?",
      answers: ["The President (of the United States)"],
    },
    {
      seq: "44",
      older65: true,
      question: "Who vetoes bills?",
      answers: ["The President (of the United States)"],
    },

    {
      seq: "45",
      question: "Who appoints federal judges? ",
      answers: ["The President (of the United States)"],
    },
    {
      seq: "46",
      question: "The executive branch has many parts. Name one.",
      answers: [
        "President (of the United States)",
        "Cabinet",
        "Federal departments and agencies",
      ],
    },
    {
      seq: "47",
      question: "What does the President's Cabinet do?",
      answers: ["Advises the President (of the United States)"],
    },
    {
      seq: "48",
      question: "What are two Cabinet-level positions?",
      answers: [
        "Attorney General",
        "Secretary of Agriculture",
        "Secretary of Commerce",
        "Secretary of Defense",
        "Secretary of Education",
        "Secretary of Energy",
        "Secretary of Health and Human Services",
        "Secretary of Homeland Security",
        "Secretary of Housing and Urban Development",
        "Secretary of the Interior",
        "Secretary of Labor",
        "Secretary of State ",
        "Secretary of Transportation",
        "Secretary of the Treasury",
        "Secretary of Veterans Affairs",
        "Vice President (of the United States)",
      ],
    },
    {
      seq: "49",
      question: "Why is the Electoral College important?",
      answers: [
        "It decides who is elected president.",
        "It provides a compromise between the popular election of the president and congressional selection.",
      ],
    },
    {
      seq: "50",
      question: "What is one part of the judicial branch?",
      answers: ["Supreme Court", "Federal Courts"],
    },
    {
      seq: "51",
      question: "What does the judicial branch do? ",
      answers: [
        "Reviews laws",
        "Explains laws",
        "Resolves disputes (disagreements) about the law",
        "Decides if a law goes against the (U.S.) Constitution ",
      ],
    },
    {
      seq: "52",
      older65: true,
      question: "What is the highest court in the United States? ",
      answers: ["Supreme Court"],
    },

    {
      seq: "53",
      question: "How many seats are on the Supreme Court?",
      answers: ["Nine (9)"],
    },

    {
      seq: "54",
      question:
        "How many Supreme Court justices are usually needed to decide a case?",
      answers: ["Five (5)"],
    },
    {
      seq: "55",
      question: "How long do Supreme Court justices serve?",
      answers: ["(For) life", "Lifetime appointment", "(Until) retirement"],
    },

    {
      seq: "56",
      question: "Supreme Court justices serve for life. Why?",
      answers: [
        "To be independent (of politics)",
        "To limit outside (political) influence",
      ],
    },
    {
      seq: "57",
      question: "Who is the Chief Justice of the United States now? ",
      answers: [
        "Visit uscis.gov/citizenship/testupdates for the name of the Chief Justice of the United States.",
      ],
    },

    {
      seq: "58",
      question: "Name one power that is only for the federal government.",
      answers: [
        "Print paper money",
        "Mint coins ",
        "Declare war",
        "Create an army",
        "Make treaties",
        "Set foreign policy",
      ],
    },
    {
      seq: "59",
      question: "Name one power that is only for the states.",
      answers: [
        "Provide schooling and education",
        "Provide protection (police)",
        "Provide safety (fire departments)",
        "Give a driver's license",
        "Approve zoning and land use",
      ],
    },
    {
      seq: "60",
      question: "What is the purpose of the 10th Amendment?",
      answers: [
        "(It states that the) powers not given to the federal government belong to the states or to the people.",
      ],
    },

    {
      seq: "61",
      older65: true,
      question: "Who is the governor of your state now? ",
      answers: [
        "Answers will vary. [District of Columbia residents should answer that D.C. does not have a governor.]",
      ],
    },
    {
      seq: "62",
      question: "What is the capital of your state?",
      answers: [
        "Answers will vary. [District of Columbia residents should answer that D.C. is not a state and does not have a capital. Residents of U.S. territories should name the capital of the territory.]",
      ],
    },
    {
      seq: "63",
      question:
        "There are four amendments to the U.S. Constitution about who can vote. Describe one of them. ",
      answers: [
        "Citizens eighteen (18) and older (can vote).",
        "You don't have to pay (a poll tax) to vote.",
        "Any citizen can vote. (Women and men can vote.)",
        "A male citizen of any race (can vote).",
      ],
    },
    {
      seq: "64",
      question:
        "Who can vote in federal elections, run for federal office, and serve on a jury in the United States?",
      answers: ["Citizens", "Citizens of the United States", "U.S. citizens"],
    },
    {
      seq: "65",
      question:
        "What are three rights of everyone living in the United States? ",
      answers: [
        "Freedom of expression",
        "Freedom of speech",
        "Freedom of assembly",
        "Freedom to petition the government",
        "Freedom of religion",
        "The right to bear arms",
      ],
    },
    {
      seq: "66",
      older65: true,
      question:
        "What do we show loyalty to when we say the Pledge of Allegiance? ",
      answers: ["The United States ", "The flag"],
    },

    {
      seq: "67",
      question:
        "Name two promises that new citizens make in the Oath of Allegiance. ",
      answers: [
        "Give up loyalty to other countries",
        "Defend the (U.S.) Constitution",
        "Obey the laws of the United States ",
        "Serve in the military (if needed)",
        "Serve (help, do important work for) the nation (if needed)",
        "Be loyal to the United States",
      ],
    },
    {
      seq: "68",
      question: "How can people become United States citizens?",
      answers: [
        "Naturalize",
        "Derive citizenship",
        "Be born in the United States",
      ],
    },
    {
      seq: "69",
      question:
        "What are two examples of civic participation in the United States?",
      answers: [
        "Vote",
        "Run for office",
        "Join a political party",
        "Help with a campaign",
        "Join a civic group",
        "Join a community group",
        "Give an elected official your opinion (on an issue)",
        "Contact elected officials",
        "Support or oppose an issue or policy",
        "Write to a newspaper",
      ],
    },
    {
      seq: "70",
      question: "What is one way Americans can serve their country?",
      answers: [
        "Vote",
        "Pay taxes",
        "Obey the law",
        "Serve in the military",
        "Run for office",
        "Work for local, state, or federal government",
      ],
    },
    {
      seq: "71",
      question: "Why is it important to pay federal taxes?",
      answers: [
        "Required by law",
        "All people pay to fund the federal government",
        "Required by the (U.S.) Constitution (16th Amendment)",
        "Civic duty",
      ],
    },
    {
      seq: "72",
      question:
        "It is important for all men age 18 through 25 to register for the Selective Service. Name one ",
      answers: [
        "reason why.,",
        "Required by law",
        "Civic duty",
        "Makes the draft fair, if needed",
      ],
    },
    {
      seq: "73",
      question: "The colonists came to America for many reasons. Name one.",
      answers: [
        "Freedom",
        "Political liberty",
        "Religious freedom",
        "Economic opportunity",
        "Escape persecution",
      ],
    },
    {
      seq: "74",
      older65: true,
      question: "Who lived in America before the Europeans arrived?",
      answers: ["American Indians", "Native Americans"],
    },
    {
      seq: "75",
      question: "What group of people was taken and sold as slaves?",
      answers: ["Africans", "People from Africa"],
    },
    {
      seq: "76",
      question:
        "What war did the Americans fight to win independence from Britain?",
      answers: [
        "American Revolution",
        "The (American) Revolutionary War",
        "War for (American) Independence",
      ],
    },
    {
      seq: "77",
      question:
        "Name one reason why the Americans declared independence from Britain.",
      answers: [
        "High taxes ",
        "Taxation without representation",
        "British soldiers stayed in Americans' houses (boarding, quartering)",
        "They did not have self-government",
        "Boston Massacre",
        "Boston Tea Party (Tea Act)",
        "Stamp Act",
        "Sugar Act",
        "Townshend Acts",
        "Intolerable (Coercive) Acts",
      ],
    },
    {
      seq: "78",
      older65: true,
      question: "Who wrote the Declaration of Independence?",
      answers: ["(Thomas) Jefferson"],
    },
    {
      seq: "79",
      question: "When was the Declaration of Independence adopted?",
      answers: ["July 4, 1776"],
    },
    {
      seq: "80",
      question: "The American Revolution had many important events. Name one.",
      answers: [
        "(Battle of) Bunker Hill",
        "Declaration of Independence ",
        "Washington Crossing the Delaware (Battle of Trenton)",
        "(Battle of) Saratoga",
        "Valley Forge (Encampment)",
        "(Battle of) Yorktown (British surrender at Yorktown)",
      ],
    },
    {
      seq: "81",
      question: "There were 13 original states. Name five.",
      answers: [
        "New Hampshire",
        "Massachusetts",
        "Rhode Island",
        "Connecticut",
        "New York",
        "New Jersey",
        "Pennsylvania",
        "Delaware",
        "Maryland",
        "Virginia",
        "North Carolina",
        "South Carolina",
        "Georgia",
      ],
    },
    {
      seq: "82",
      question: "What founding document was written in 1787?",
      answers: ["(U.S.) Constitution"],
    },
    {
      seq: "83",
      question:
        "The Federalist Papers supported the passage of the U.S. Constitution. Name one of the writers.",
      answers: [
        "(James) Madison",
        "(Alexander) Hamilton",
        "(John) Jay",
        "Publius",
      ],
    },
    {
      seq: "84",
      question: "Why were the Federalist Papers important?",
      answers: [
        "They helped people understand the (U.S.) Constitution.",
        "They supported passing the (U.S.) Constitution.",
      ],
    },
    {
      seq: "85",
      question: "Benjamin Franklin is famous for many things. Name one. ",
      answers: [
        "Founded the first free public libraries",
        "First Postmaster General of the United States",
        "Helped write the Declaration of Independence",
        "Inventor",
        "U.S. diplomat",
      ],
    },

    {
      seq: "86",
      older65: true,
      question: "George Washington is famous for many things. Name one. ",
      answers: [
        "Father of Our Country",
        "First president of the United States",
        "General of the Continental Army",
        "President of the Constitutional Convention",
      ],
    },

    {
      seq: "87",
      question: "Thomas Jefferson is famous for many things. Name one.",
      answers: [
        "Writer of the Declaration of Independence",
        "Third president of the United States",
        "Doubled the size of the United States (Louisiana Purchase)",
        "First Secretary of State",
        "Founded the University of Virginia",
        "Writer of the Virginia Statute on Religious Freedom",
      ],
    },
    {
      seq: "88",
      question: "James Madison is famous for many things. Name one. ",
      answers: [
        "Father of the Constitution",
        "Fourth president of the United States",
        "President during the War of 1812",
        "One of the writers of the Federalist Papers",
      ],
    },
    {
      seq: "89",
      question: "Alexander Hamilton is famous for many things. Name one.",
      answers: [
        "First Secretary of the Treasury",
        "One of the writers of the Federalist Papers",
        "Helped establish the First Bank of the United States",
        "Aide to General George Washington",
        "Member of the Continental Congress",
      ],
    },
    {
      seq: "90",
      question: "What territory did the United States buy from France in 1803?",
      answers: ["Louisiana Territory", "Louisiana"],
    },
    {
      seq: "91",
      question: "Name one war fought by the United States in the 1800s. ",
      answers: [
        "War of 1812",
        "Mexican-American War",
        "Civil War",
        "Spanish-American War",
      ],
    },
    {
      seq: "92",
      question: "Name the U.S. war between the North and the South.",
      answers: ["The Civil War"],
    },
    {
      seq: "93",
      question: "The Civil War had many important events. Name one. ",
      answers: [
        "(Battle of) Fort Sumter ",
        "Emancipation Proclamation",
        "(Battle of) Vicksburg",
        "(Battle of) Gettysburg ",
        "Sherman's March",
        "(Surrender at) Appomattox",
        "(Battle of) Antietam/Sharpsburg",
        "Lincoln was assassinated.",
      ],
    },

    {
      seq: "94",
      older65: true,
      question: "Abraham Lincoln is famous for many things. Name one.  ",
      answers: [
        "Freed the slaves (Emancipation Proclamation)",
        "Saved (or preserved) the Union",
        "Led the United States during the Civil War",
        "16th president of the United States",
        "Delivered the Gettysburg Address",
      ],
    },
    {
      seq: "95",
      question: "What did the Emancipation Proclamation do?",
      answers: [
        "Freed the slaves ",
        "Freed slaves in the Confederacy ",
        "Freed slaves in the Confederate states ",
        "Freed slaves in most Southern states",
      ],
    },
    {
      seq: "96",
      question: "What U.S. war ended slavery?",
      answers: ["The Civil War"],
    },
    {
      seq: "97",
      question:
        "What amendment gives citizenship to all persons born in the United States?",
      answers: ["14th Amendment"],
    },
    {
      seq: "98",
      question: "When did all men get the right to vote?",
      answers: [
        "After the Civil War",
        "During Reconstruction ",
        "(With the) 15th Amendment",
      ],
    },
    {
      seq: "99",
      question: "Name one leader of the women's rights movement in the 1800s.",
      answers: [
        "Susan B. Anthony",
        "Elizabeth Cady Stanton",
        "Sojourner Truth",
        "Harriet Tubman",
        "Lucretia Mott",
        "Lucy Stone",
      ],
    },
    {
      seq: "100",
      question: "Name one war fought by the United States in the 1900s.",
      answers: [
        "World War I",
        "World War II ",
        "Korean War ",
        "Vietnam War ",
        "(Persian) Gulf War",
      ],
    },
    {
      seq: "101",
      question: "Why did the United States enter World War I?",
      answers: [
        "Because Germany attacked U.S. (civilian) ships",
        "To support the Allied Powers (England, France, Italy, and Russia)",
        "To oppose the Central Powers (Germany, Austria-Hungary, the Ottoman Empire, and Bulgaria)",
      ],
    },
    {
      seq: "102",
      question: "When did all women get the right to vote? ",
      answers: ["After World War I", "(With the) 19th Amendment"],
    },
    {
      seq: "103",
      question: "What was the Great Depression?",
      answers: ["Longest economic recession in modern history"],
    },
    {
      seq: "104",
      question: "When did the Great Depression start?",
      answers: ["The Great Crash (1929)", "Stock market crash of 1929"],
    },
    {
      seq: "105",
      question:
        "Who was president during the Great Depression and World War II? ",
      answers: ["(Franklin) Roosevelt"],
    },
    {
      seq: "106",
      question: "Why did the United States enter World War II?",
      answers: [
        "(Bombing of) Pearl Harbor",
        "Japanese attacked Pearl Harbor",
        "To support the Allied Powers (England, France, and Russia)",
        "To oppose the Axis Powers (Germany, Italy, and Japan)",
      ],
    },
    {
      seq: "107",
      question: "Dwight Eisenhower is famous for many things. Name one. ",
      answers: [
        "General during World War II",
        "President at the end of (during) the Korean War",
        "34th president of the United States",
        "Signed the Federal-Aid Highway Act of 1956 (Created the Interstate System) ",
      ],
    },
    {
      seq: "108",
      question: "Who was the United States' main rival during the Cold War?",
      answers: ["Soviet Union", "USSR", "Russia"],
    },
    {
      seq: "109",
      question:
        "During the Cold War, what was one main concern of the United States?",
      answers: ["Communism", "Nuclear war"],
    },
    {
      seq: "110",
      question: "Why did the United States enter the Korean War?",
      answers: ["To stop the spread of communism"],
    },
    {
      seq: "111",
      question: "Why did the United States enter the Vietnam War?",
      answers: ["To stop the spread of communism"],
    },
    {
      seq: "112",
      question: "What did the civil rights movement do?",
      answers: ["Fought to end racial discrimination"],
    },
    {
      seq: "113",
      older65: true,
      question: "Martin Luther King, Jr. is famous for many things. Name one. ",
      answers: [
        `Fought for civil rights`,
        `Worked for equality for all Americans`,
        `Worked to ensure that people would "not be judged by the color of their skin, but by the content of their character`,
      ],
    },
    {
      seq: "114",
      question: "Why did the United States enter the Persian Gulf War?",
      answers: ["To force the Iraqi military from Kuwait"],
    },
    {
      seq: "115",
      older65: true,
      question:
        "What major event happened on September 11, 2001 in the United States?",
      answers: [
        "Terrorists attacked the United States ",
        "Terrorists took over two planes and crashed them into the World Trade Center in New York City",
        "Terrorists took over a plane and crashed into the Pentagon in Arlington, Virginia",
        "Terrorists took over a plane originally aimed at Washington, D.C., and crashed in a field in Pennsylvania",
      ],
    },
    {
      seq: "116",
      question:
        "Name one U.S. military conflict after the September 11, 2001 attacks. ",
      answers: [
        "(Global) War on Terror",
        "War in Afghanistan ",
        "War in Iraq ",
      ],
    },
    {
      seq: "117",
      question: "Name one American Indian tribe in the United States.",
      answers: [
        "Apache",
        "Blackfeet",
        "Cayuga",
        "Cherokee",
        "Cheyenne",
        "Chippewa",
        "Choctaw",
        "Creek",
        "Crow",
        "Hopi",
        "Huron",
        "Inupiat",
        "Lakota",
        "Mohawk ",
        "Mohegan",
        "Navajo",
        "Oneida",
        "Onondaga ",
        "Pueblo",
        "Seminole ",
        "Seneca",
        "Shawnee",
        "Sioux ",
        "Teton",
        "Tuscarora",
        "For a complete list of tribes, please visit bia.gov.",
      ],
    },
    {
      seq: "118",
      question: "Name one example of an American innovation.",
      answers: [
        "Light bulb",
        "Automobile (cars, internal combustion engine)",
        "Skyscrapers ",
        "Airplane",
        "Assembly line",
        "Landing on the moon",
        "Integrated circuit (IC)",
      ],
    },
    {
      seq: "119",
      question: "What is the capital of the United States?",
      answers: ["Washington, D.C. "],
    },
    {
      seq: "120",
      question: "Where is the Statue of Liberty?  ",
      answers: [
        "New York (Harbor)  ",
        "Liberty Island [Also acceptable are New Jersey, near New York City, and on the Hudson (River).]",
      ],
    },
    {
      seq: "121",
      older65: true,
      question: "Why does the flag have 13 stripes?",
      answers: [
        "(Because there were) 13 original colonies",
        "(Because the stripes) represent the original colonies",
      ],
    },
    {
      seq: "122",
      question: "Why does the flag have 50 stars?",
      answers: [
        "(Because there is) one star for each state",
        "(Because) each star represents a state",
        "(Because there are) 50 states",
      ],
    },
    {
      seq: "123",
      question: "What is the name of the national anthem?",
      answers: ["The Star-Spangled Banner"],
    },
    {
      seq: "124",
      question:
        "The Nation's first motto was 'E Pluribus Unum.' What does that mean?",
      answers: ["Out of many, one", "We all become one"],
    },
    {
      seq: "125",
      question: "What is Independence Day?",
      answers: [
        "A holiday to celebrate U.S. independence (from Britain)",
        "The country's birthday",
      ],
    },
    {
      seq: "126",
      older65: true,
      question: "Name three national U.S. holidays.",
      answers: [
        "New Year's Day ",
        "Martin Luther King, Jr. Day ",
        "Presidents Day (Washington's Birthday)",
        "Memorial Day ",
        "Independence Day ",
        "Labor Day ",
        "Columbus Day ",
        "Veterans Day ",
        "Thanksgiving Day",
        "Christmas Day",
      ],
    },
    {
      seq: "127",
      question: "What is Memorial Day?",
      answers: ["A holiday to honor soldiers who died in military service"],
    },
    {
      seq: "128",
      question: "What is Veterans Day?",
      answers: [
        "A holiday to honor people in the (U.S.) military",
        "A holiday to honor people who have served (in the U.S. military)",
      ],
    },
  ],
};
